import React, { useEffect } from 'react'
import { PageProps } from 'gatsby'
import { LayoutProps } from '../components/Layout.context'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import Button from '../components/Button'
import { useLocation } from '../components/Layout'
import { navigate } from 'gatsby'

const Container = styled.div`
  background: #fff;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px 40px;
  align-items: center;
`

const Title = styled.h1`
  font-style: normal;
  font-weight: bold;
  text-align: center;
  color: ${({ theme }) => theme.colors.body};
  font-size: 42px;
  line-height: 70px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 92px;
    line-height: 110px;
  }
`

const useValidationRedirect = () => {
  const location = useLocation()

  useEffect(() => {
    if (location?.pathname.match(/\/validation\/$/)) {
      const target = location?.pathname.replace(/(.*)\/validation\/$/, '$1/')
      navigate(target, { replace: true })
    }
  }, [location])
}

const NotFoundPage: React.FC<PageProps> = () => {
  useValidationRedirect()

  return (
    <Container>
      <StaticImage
        src="../images/404.jpg"
        alt="404"
        placeholder="blurred"
        quality={100}
      />
      <Title>Erreur 404</Title>
      <Button.Link to="/">revenir sur la home</Button.Link>
    </Container>
  )
}

const layoutProps: LayoutProps = {
  title: 'Erreur 404',
}

export default Object.assign(NotFoundPage, {
  layoutProps,
})
